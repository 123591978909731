export default function FooterComponent() {
  return (
    <>
      <footer id='footer'>
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6'>
                {/* <!-- copyright --> */}
                <strong className='copyright'>
                  <i className='fa fa-copyright'></i> Copyright 2023 - Francisco Rodriguez | Clara Barraza | Magda Monsalve
                </strong>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
