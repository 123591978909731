/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Outlet } from 'react-router-dom';
import './App.css';
import HeaderComponent from './components/header/header';
import FooterComponent from './components/footer/footer';
// import HomeComponent from './views/home/home';
// import DetailsUbicacion from './views/details-ubicacion/details-ubicacion';

function App() {

  const top = () : void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Opcional: agrega un desplazamiento suave
    });
  }
  return (
    <>
      {/* <div className='preloader' id='pageLoad'>
        <div className='holder'>
          <div className='coffee_cup'></div>
        </div>
      </div> */}
      <div id='wrapper'>
        <div className='page-wrapper'>
          <HeaderComponent />
          <></>
          <Outlet />
        </div>
        <FooterComponent />
      </div>
      <div className='scroll-holder text-center' onClick={top}>
        <a id='scroll-to-top'>
          <i className='icon-arrow-down'></i>
        </a>
      </div>
    </>
  );
}

export default App;
