import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import HomeComponent from './views/home/home';
import DetailsUbication from './views/details-ubication/details-ubicacion';
import DetailsAtractive from './views/details-atractive/details-atractive';
import ViewPdf from './components/view-pdf/view-pdf';
import { Provider } from 'react-redux';
import Atractive from './views/atractive/atractive';
import store from './core/store/header/header.store';
import AllUbications from './views/all-ubications/all-ubications';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Router>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<HomeComponent />} />
          <Route path='home' element={<HomeComponent />} />
          <Route path="details-ubication/:id" element={<DetailsUbication />} />
          <Route path="details-atractive/:id" element={<DetailsAtractive />} />
          <Route path="atractive/:id" element={<Atractive />} />
          <Route path="recomendaciones/:pdf" element={<ViewPdf />} />
          <Route path="all-ubications" element={<AllUbications />} />
        </Route>
      </Routes>
    </Router>
  </Provider>

  // </React.StrictMode>
);
reportWebVitals();
