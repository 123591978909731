import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { useState } from 'react';

const SimpleMap = (props: any) => {
  const [center] = useState<any>([11.273244193137987, -73.31110782670481]); // Cambia según tus necesidades

  const defaultIcon = new L.Icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
  });

  return (
    <MapContainer center={center} zoom={13} style={{ height: '400px', width: '100%' }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={center} icon={defaultIcon}>
        <Popup>
          <label className=''>Dibulla</label>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default SimpleMap;
