import React from 'react';
import './slider.css';
const Slider = () => {
  const images = [
    {
      src: '/assets/img/ubicaciones/dibulla_.jpeg',
      title: 'DISFRUTA DE LA MAGIA DE DIBULLA "',
      alt: 'Img',
    },
    {
      src: '/assets/img/ubicaciones/puntaremedios.jpeg',
      title: 'MARAVILLAS DE LUGARES',
      alt: 'Img',
    },
    {
      src: '/assets/img/ubicaciones/playasRemedios.jpeg',
      title: 'CONTEMPLA El HERMOSO MAR',
      alt: 'Img',
    },
  ];

  return (
    <div className="slider-frame">
      <ul>
        {images.map((image, index) => (
          <li key={index}>
            <img
              draggable="false"
              className="img-slider"
              src={image.src}
              alt={image.alt}
            />
            <h1 className="image-title">VIVE LA MAGIA DE DIBULLA</h1>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Slider;
