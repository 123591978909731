/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Ubicaciones } from '../../core/data/ubicaciones';
import { useDispatch } from 'react-redux';
import { IUbicaciones } from '../../core/interfaces/ubicaciones.interface';
import './all-ubication.css';

export default function AllUbications() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const sitiosList = (): JSX.Element => {
    const items: JSX.Element[] = Ubicaciones.map((element: IUbicaciones) => {
      return (
        <div className="content-img-all text-center text-uppercase">
          <Link to={'/details-ubication/' + element.id}>
            <h3 className="row title-img">{element.name}</h3>
          </Link>
          <div className="row col-md-12 img-div-all">
            <Link to={'/details-ubication/' + element.id}>
              <img
                draggable="false"
                src={element.image}
                alt="imagen descripcion"
              />
            </Link>
          </div>
        </div>
      );
    });

    return <div className="row">{items}</div>;
  };

  useEffect(() => {
    dispatch({ type: 'SET_HOME', payload: false });

    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Opcional: agrega un desplazamiento suave
    });
  }, [dispatch, id]);
  return (
    <>
      <main id="main">
        {/* <!-- recent block --> */}
        <aside className="recent-block recent-list recent-wide-thumbnail">
            <h2 className="text-center text-uppercase">
              TENEMOS ESTOS SITIOS Y EVENTOS
            </h2>
          <div className="container-all">
            {sitiosList()}
          </div>
        </aside>
      </main>
    </>
  );
}
