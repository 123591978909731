import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IAtractivos } from '../../core/interfaces/atractivos.interfaces';
import { Atractivos } from '../../core/data/atractivos';
import { useDispatch } from 'react-redux';
import './atractive.css'

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Atractive() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [attractive, setAttractive] = useState<IAtractivos>(
    Atractivos.filter((sel: IAtractivos) => sel.id === Number(id))[0]
  );



  useEffect(() => {
    dispatch ({ type: 'SET_HOME', payload: false });
    setAttractive(
      Atractivos.filter((sel: IAtractivos) => sel.id === Number(id))[0]
    );

    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Opcional: agrega un desplazamiento suave
    });
  }, [dispatch, id]);

  return (
    <>
      {/* <Slider></Slider> */}
      <main id='main' style={{ marginTop: '100px' }}>
        {/* <!-- main tour information --> */}
        <section className='container-fluid trip-info'>
          <div className='same-height two-columns row'>
            <div className='height col-md-6'>
              {/* <!-- top image slideshow --> */}
              <div id='tour-slide'>
                <div className='slide'>
                  <div className='bg-stretch'>
                    {' '}
                    <img
                      src={attractive.image}
                      alt='imagen descripcion'
                      height='1104'
                      width='966'
                    />
                  </div>
                </div>
                <div className='slide' id="slide-2">
                  <div className='bg-stretch'>
                    {' '}
                    <img
                      src={attractive.image}
                      alt='imagen descripcion'
                      height='1104'
                      width='966'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='description' style={{marginTop: '40px', paddingLeft: '40px', paddingRight: '30px'}}>
                <h1 className='content-main-heading'>
                 {attractive.name}
                </h1>
                <p style={{fontSize: '1.7rem'}}>
                  {attractive.description}
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
