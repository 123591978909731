/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  IAtractivos,
  ITiposAtractivos,
} from '../../core/interfaces/atractivos.interfaces';
import { Atractivos, TiposAtractivos } from '../../core/data/atractivos';
import { useDispatch } from 'react-redux';
import './details-atractive.css'

export default function DetailsAtractive() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [tipoAtractivo, setTipoAtractivo] = useState<ITiposAtractivos>(
    TiposAtractivos.filter((sel: ITiposAtractivos) => sel.id === Number(id))[0]
  );

  const sitiosList = (): JSX.Element => {
    const rows = Atractivos.filter(
      (sel: IAtractivos) => sel.tipoAtractivoId === tipoAtractivo.id
    );
    const items: JSX.Element[] = rows.map((element: IAtractivos) => {
      return (
        <article
          className='col-sm-6 col-md-4 article'
          style={{ paddingBottom: '30px' }}
        >
          <div className='thumbnail'>
            <h3 className='no-space' style={{ height: '50px' }}>
              <Link to={'/atractive/' + element.id}>{element.name}</Link>
            </h3>
            <strong className='info-title' style={{ height: '50px' }}>
              <Link to={'/atractive/' + element.id}>{element.name}</Link>
            </strong>
            <div className='img-wrap'>
              <Link to={'/atractive/' + element.id}>
                <img
                  className='img-detail'
                  src={element.image}
                  width='250'
                  alt='imagen descripcion'
                />
              </Link>
            </div>
          </div>
        </article>
      );
    });

    return <div className='row'>{items}</div>;
  };

  useEffect(() => {
    dispatch({ type: 'SET_HOME', payload: false });
    setTipoAtractivo(
      TiposAtractivos.filter(
        (sel: ITiposAtractivos) => sel.id === Number(id)
      )[0]
    );

    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Opcional: agrega un desplazamiento suave
    });
  }, [dispatch, id]);
  return (
    <>
      <section
        className='banner banner-inner parallax header-detail'
        data-stellar-background-ratio='0.5'
        style={{
          backgroundImage: 'url(' + tipoAtractivo.imageDetail + ')',
          backgroundSize: '100% 100%'
        }}
      >
        <div className='banner-text'>
          <div className='center-text'>
            <div className='container'>
              <h1>{tipoAtractivo.name}</h1>
              <strong className='subtitle'>CONOCE LOS LUGARES</strong>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- main container --> */}
      <main id='main'>
        {/* <!-- recent block --> */}
        <aside className='recent-block recent-list recent-wide-thumbnail'>
            <h2 className='text-center text-uppercase'>
              TENEMOS ESTOS SITIOS Y EVENTOS
            </h2>
          <div className='container'>
            {sitiosList()}
          </div>
        </aside>
      </main>
    </>
  );
}
