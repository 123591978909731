import {
  IAtractivos,
  ITiposAtractivos,
} from '../interfaces/atractivos.interfaces';

export const TiposAtractivos: ITiposAtractivos[] = [
  {
    id: 1,
    name: 'Atractivo Cultural',
    image: '/assets/img/tipoAtractivo/cultural.jpg',
    imageDetail: '/assets/img/tipoAtractivo/cultural.jpg',
    description: '',
  },
  {
    id: 2,
    name: 'Atractivo de evento',
    image: '/assets/img/tipoAtractivo/eventos.jpg',
    imageDetail: '/assets/img/tipoAtractivo/eventos.jpg',
    description: '',
  },
  {
    id: 3,
    name: 'Atractivo de sitio ',
    image: '/assets/img/tipoAtractivo/sitios.jpeg',
    imageDetail: '/assets/img/tipoAtractivo/sitios.jpeg',
    description: '',
  },
  {
    id: 4,
    name: 'Atractivo historico',
    image: '/assets/img/tipoAtractivo/historico.jpg',
    imageDetail: '/assets/img/tipoAtractivo/historico.jpg',
    description: '',
  },
  {
    id: 5,
    name: 'Atractivo Natural',
    image: '/assets/img/tipoAtractivo/natural.jpeg',
    imageDetail: '/assets/img/tipoAtractivo/natural.jpeg',
    description: '',
  },
  {
    id: 6,
    name: 'Atractivo turistico natural',
    image: '/assets/img/tipoAtractivo/anatural.jpg',
    imageDetail: '/assets/img/tipoAtractivo/anatural.jpg',
    description: '',
  },
];

export const Atractivos: IAtractivos[] = [
  {
    id: 1,
    name: 'Monumento al Campesino Cultivador de Plátano',
    image: '/assets/img/atractive/monumentoscampesino.jpg',
    tipoAtractivoId: 1,
    ubicacionId: 3,
    description:
      'Este monumento del escultor Haine de la Hoz es un homenaje a los campesinos agricultores en especial a los recolectores de plátano que es una de las principales fuentes económicas del municipio. Consiste en la escultura de bronce de un hombre sobre su burro cargando dos costales de plátano sobre una plataforma elevada en cuyas bases se ubican fuentes de agua y jardineras. Si bien el monumento ya existía su entorno fue remodelado y entregado el 15 de julio de 2019.',
  },
  {
    id: 2,
    name: 'Fiestas Patronales de San Martín de Tours',
    image: '/assets/img/atractive/FiestasPatronalesdeSanMartíndeTours.jpeg',
    tipoAtractivoId: 1,
    ubicacionId: 7,
    description:
      'Festividad eclesiástica para conmemorar a San Martin de Tours patrono del corregimiento de Mingueo. La fiesta se celebra anualmente el 11 de noviembre durante ella se realiza la procesión del patrono la tradicional eucaristía los bautizos y la popular quema del castillo.En el marco de esta fiesta se realiza el Festival de la Troncal del Caribe.',
  },
  {
    id: 3,
    name: 'Fiestas Patronales de Nuestra Señora del Pilar',
    image: '/assets/img/atractive/fiestapatronalesdepilar.jpg',
    tipoAtractivoId: 1,
    ubicacionId: 8,
    description:
      'Festividad eclesiástica para conmemorar a Nuestra Señora del Pilar patrona del municipio de Dibulla. La fiesta inicia con las novenas el 3 de octubre el 12 de octubre se realiza la celebración con la procesión de la patrona Nuestra Señora del Pilar la tradicional eucaristía y bautizos. Nuestra Señora del Pilar es enaltecida porque a ella se le debe el Milagro del Pilar ocurrido hace más de 100 años cuando la plaga de langostas invadió los cultivos de la región las súplicas y ruegos de los feligreses lograron que al sacar la imagen de la patrona las langostas se retiraran de los cultivos y se posaran en la frente de la imagen del Pilar.',
  },
  {
    id: 4,
    name: 'Arhuacos',
    image: '/assets/img/atractive/arhuacos.jpeg',
    tipoAtractivoId: 1,
    ubicacionId: 8,
    description:
      'Los Arhuacos también llamados Ika es uno de los cuatro grupos indígenas que habitan la Sierra Nevada de Santa Marta asentándose en la vertiente occidental de la misma en los departamentos de Cesar La Guajira y Magdalena. La Sierra Nevada es considerada  como el corazón o centro del mundo y toda su geografía es un espacio sagrado. El Mamo representa la máxima autoridad de la estructura social el cual debido a su sabiduría y conocimiento permite el equilibrio entre las fuerzas celestiales y los hombres ellos toman decisiones y ejercen justicia. Sus viviendas son redondas o rectangulares hechas de bahareque y techadas en paja las viviendas se ubican alrededor de una casa ceremonial. Su lengua deriva de la lengua chibchense su principal actividad económica es la cría de ganado vacuno y lanar además de la agricultura. La vestimenta que los caracteriza la elaboran ellos mismos y está tejida en lana color blanca consiste en una túnica que llega hasta medio muslo un pantalón angosto hasta el tobillo un gorro de fique en forma de cono alto y casi puntiagudos y una mochila con figuras geométricas representaciones de animales y otros objetos de su cosmovisión y de la cotidianidad de esta comunidad. Esta última es además otra fuente de ingresos pues la elaboran y comercializan en los centros poblados cercanos.En la actualidad no es posible visitar ningún poblado arhuaco desde el Municipio de Dibulla.',
  },
  {
    id: 5,
    name: 'Koguis',
    image: '/assets/img/atractive/koguis.jpeg',
    tipoAtractivoId: 1,
    ubicacionId: 8,
    description:
      'Los Koguis junto a otras tres etnias habitan la Sierra Nevada de Santa Marta considerado por ellos como un espacio sagrado comparada con un cuerpo humano donde los cerros y montañas son personajes místicos que dan origen a la vida y las fuentes de agua son consideradas la sangre que irriga todo el cuerpo.La figura central de autoridad es el Mamo quién encarna la ley sagrada y es considerado como un sabio con poderes sobrenaturales. Creen que el origen de la humanidad procede de los hermanos mayores originarios de la Sierra y todos los que llegaron después son los hermanos menores.Su lengua se denomina kouguian o kogui y pertenece a la familia lingüística Chibcha su ocupación principal es la agricultura la caza y la pesca además de la fabricación de mochilas tejidas en fibra de fique o algodón que tiñen utilizando raíces y cortezas de árboles y las cuales comercializan en los centros poblados.En el municipio de Dibulla se ubican pueblos koguis en los valles de los ríos Palomino y Ancho. Uno de ellos es el poblado de Tungueka donde habitan casi cien familias en el se puede observar sus pequeñas casas circulares construidas de bahareque y techadas con hojas de palma en forma cónica un templo femenino un templo masculino y las aulas de la escuela inmersas por todo el pueblo. Los visitantes pueden acceder al poblado recorrerlo y seguir el sendero hacia el Saltillo un pozo de agua ubicado en el río Ancho.',
  },
  {
    id: 6,
    name: 'Wiwas',
    image: '/assets/img/atractive/wiwas.jpg',
    tipoAtractivoId: 1,
    ubicacionId: 8,
    description:
      'La palabra “Wiwa” significa originarios de tierras cálidas este grupo también es conocido como Arzarios. Es uno de los cuatro pueblos que habita la Sierra Nevada de Santa Marta distribuyéndose en los municipios de Dibulla Riohacha y San Juan del Cesar del Departamento de La Guajira.En los poblados Wiwas se aprecian edificaciones rectangulares usadas como viviendas y otras construcciones para uso ceremonial efectuado por las autoridades tradicionales: los Mamos y los Sagas. Según su cosmovisión existen personajes sobrenaturales que han dado origen al mundo los cuales se representan en dioses naturales como la luna y el sol.Su lengua el Damana pertenece a la familia lingüística Chibcha. Su economía y alimentación se basa en el cultivo de batata yuca plátano arracacha piña calabaza malanga café arroz ají y coca además de la cría de animales de corral y cerdos. Los hombres visten un pantalón una camisa larga que cubre las rodillas y  sobrepasa el pantalón y abarcas con base de caucho. Las mujeres visten un traje blanco largo que cubre hasta más abajo de la rodilla. Suelen usar dos tipos de mochilas: el duadu y el suzu. La primera sirve de uso personal y se elabora con algodón mientras que para la segunda se usa la fibra del fique.Por instrucción del cabildo no es posible la visita a sus asentamientos o pueblos indígenas.',
  },
  {
    id: 7,
    name: 'Encuentro nacional e internacional de danzas folcloricas por pareja ',
    image: '/assets/img/atractive/encuentronacionalesdanzasfolcloricas.jpg',
    tipoAtractivoId: 2,
    ubicacionId: 3,
    description:
      'En este evento cultural se desarrollan dos galas en la tarima principal de la cabecera municipal de Dibulla y 4 Stop Cultural llevando muestras de danzas por parejas a los corregimientos de La Punta de los Remedios, Mingueo y La Flores y en lugares la cabecera municipal de Dibulla. Además, en el marco de este festival se realizan talleres prácticos de Danzas dictado por maestros de importante reconocimiento y trayectoria abierto al público, en el que participan estudiantes de las instituciones educativas, escuelas de danzas, parejas de bailarines del encuentro y por supuesto al público general. Así mismo se realiza un desfile de apertura para que la comunidad pudiera conocer los departamentos y países que participan del encuentro. Un evento que coyuntural con el festival del Plátano, Rio y Mar y con las fiestas patronales del municipio, en la que siempre ha habido un gran público en todos y cada uno de los eventos',
  },
  {
    id: 8,
    name: 'Carnaval de la Punta de los Remedios',
    image: '/assets/img/atractive/CarnavalPunta.jpeg',
    tipoAtractivoId: 2,
    ubicacionId: 5,
    description:
      'Tal cual se daba en sus orígenes, cuando la comunidad de La Punta de los Remedios, reunida espontáneamente disfrutaba con los disfraces, las cumbiambas en los salones y más tarde en K-Z y verbenas; consolidándose como la expresión autóctona más representativa de un pueblo alegre que goza sus fiestas de principio a fin. El Carnaval de la Punta de los Remedios es un acontecimiento que agrupa hechos de expresiones, manifestaciones culturales y eventualidades populares, efectuadas, según la tradición oral, desde finales de 1800. Eventos entre desfiles, disfraces, muestras culturales, verbenas y bailes populares que se han fortalecido a través del acompañamiento institucional que desde la Alcaldía municipal ofrecen para su desarrollo, unidos además a otros agentes con los que se suman el esfuerzo ilimitado de sus organizadores, adscritos a una fundación que con ahínco, por décadas han creado una variada programación, participativa, bien organizada y de calidad al público. Es una de las fiestas mas antiguas del municipio de Dibulla y con ello se han realizado cambios importantes en su estructura y desarrollo, también en sus escenarios.',
  },
  {
    id: 9,
    name: 'Festival cultural y reinado del camaron',
    image: '/assets/img/atractive/festivalreinadocamaron.jpg',
    tipoAtractivoId: 2,
    ubicacionId: 5,
    description:
      'Históricas, emotivas, llenas de folclor, cultura y tradición así han sido las versiones del Festival Cultural y Reinado del Camarón, un evento inédito que nace en el año 2013 de una conversación de los hermanos Pacheco (Alexánder y Luis Iván) éste último, emigrando del desolado momento que se vivía en la población de La Punta de los Remedios, le propone a su hermano, inventarse algo para divertir al pueblo; Alex, con su experiencia en la organización de eventos culturales, quién por muchos años dirigió la fundación de los carnavales de La Punta de los Remedios, le responde que se debería hacer un evento en el que se enalteciera la pesca del camarón, ya que es la principal actividad económica de la comunidad, fué así entonces, que reunieron a algunos miembros de la familia Pacheco y a los vecinos de la calle Las Vacas, para que entre todos desarrollaran la mágica idea del I Festival Comunal del Camarón, todos, con entusiasmo hicieron los preparativos para realizar “un evento del pueblo y para el pueblo”.',
  },
  {
    id: 10,
    name: 'Festival cultural y reinado del mango y el banano',
    image: '/assets/img/atractive/festivalreinadocamaron.jpg',
    tipoAtractivoId: 2,
    ubicacionId: 6,
    description:
      '"Gracias a la riqueza de sus expresiones folclóricas, el colorido, la magia y la belleza de las mujeres y diversidad agrícola de los productos del Mango y el banano se celebra en el corregimiento de las Flores, municipio de Dibulla el FESTIVAL CULTURAL Y REINADO DEL MANGO Y EL BANANO. En el mes de junio de cada año, en un puente festivo, se vive en estas tierras la esencia cultural de una comunidad que aviva con todo su esplendor sus fiestas más populares que se desarrolla en esta comunidad, un evento que surge de la idea de amigos de esta fructífera tierra que deciden realizar su primer festival en el mes de Mayo de 1997. Para el año 2008 deciden constituirse como fundación del Festival del Mango y el Banano, una organización que proyectó las fiestas, logrando un expansión y reconocimiento; entre desfiles folclóricos, reinado, muestras gastronómicas derivada de los productos del Mango y el Banano."',
  },
  {
    id: 11,
    name: 'Festival agroindustrial y cultural de la troncal del caribe',
    image: '/assets/img/atractive/agroindustrialculturaltroncal.jpg',
    tipoAtractivoId: 2,
    ubicacionId: 7,
    description:
      'Un festival lleno de magia, color y tradición, todos los años los habitantes del corregimiento de Mingueo, realizan un hermoso evento ideado por amigos de ésta comunidad ubicada en la troncal del caribe que decidieron en el 2015 organizar la primera versión de FESTIVAL AGROAMBIENTAL Y CULTURAL DE LA TRONCAL DEL CARIBE. En concordancia con las fiestas patronales del pueblo todos los 11 de Noviembre se dan cita para rescatar las costumbres, valores y enaltecer sus riquezas agrícolas y culturales.Con esfuerzo, dedicación la junta organizadora de estas festividades con la finalidad de mostrar la idiosincrasia de la región organizan una variada programación artística y cultural, entre ferias, reinado, muestras folclóricas y especialmente la promoción del talento local.',
  },
  {
    id: 12,
    name: 'Festival del Jaguar',
    image: '/assets/img/atractive/festivaljaguar.jpg',
    tipoAtractivoId: 2,
    ubicacionId: 9,
    description:
      'Armonía con el medio ambiente y la música es la insignia del Festival del Jaguar, un festival que se viene realizando desde el año 2104 y nace de la necesidad de generar espacios culturales y lúdicos en zonas vulneradas azotadas por la violencia, el Festival Jaguar se lleva a cabo en el corregimiento de Palomino, un evento que se realiza anualmente con el fin de promover una cultura responsable, desarrollo sostenible a través de actividades ecológica, artística, visual y música, se constituye en un escenario donde se presentan diferentes manifestaciones artísticas en el que a ritmo de terapia criolla, champeta, electrónica, cumbia tropical y afrodelia, los asistentes viven dos días con lo mejor de los sonidos alternativos nacionales e internacionales.',
  },
  {
    id: 13,
    name: 'Festival del Plátano, rio y mar',
    image: '/assets/img/atractive/Festival Platanorioymar.jpg',
    tipoAtractivoId: 2,
    ubicacionId: 15,
    description:
      'El Festival del Plátano, Rio y Mar, es un gran embajador de Dibulla en Colombia y el mundo, su alegría, tradición y su gente son la mejor carta de presentación. Estas festividades se celebran alrededor de la Virgen del Pilar, santa patrona de esta comunidad. Cabe mencionar que Dibulla fue reconocido hace más de dos décadas por sus plátanos, “tipo exportación” destacar además las comidas típicas derivadas de éste, como la arepa de palo, la harina de plátano, mazamorra, dulces de maduro, tortas, entre otros. Un evento dónde concurren propios y visitantes, el primer encuentro popular que dio surgimiento a esta celebración se dio en 1976, cuando la señora Amparo Padilla',
  },
  {
    id: 14,
    name: 'Plaza Pajalito y Tarima Carlos Huertas',
    image: '/assets/img/atractive/plazapajalito.jpeg',
    tipoAtractivoId: 3,
    ubicacionId: 15,
    description:
      'Este hermoso panorama que procrea un escenario ideal para el encuentro y eventos culturales para el disfrute de Dibulleros y visitantes; localizada contiguo a la Boca y con vista al Mar Caribe, en ella se muestra la imponente Tarima Carlos Huertas, el mas grande compositor nacido en éstas tierras y que él mismo profesa en una de sus canciones “Nací en Dibulla Frente al Mar Caribe”. Este lugar puede ser visitado de manera permanente cualquier día de la semana y que se ha convertido en un importante sitio de interés cultural en el que se puede disfrutar de su hermosa arquitectura, en el que además cuenta con una glorieta que permite el tránsito de vehículos que inicia con la vista del Rio Jerez y finaliza sobre un panorama a cielo abierto en la que se visibiliza las playas y el mar Caribe.',
  },
  {
    id: 15,
    name: 'Murales Multicolores sobre la cultura Dibullera',
    image: '/assets/img/atractive/muralescolores.jpeg',
    tipoAtractivoId: 3,
    ubicacionId: 15,
    description:
      'Los murales multicolores son un espacio emblemático de nuestra cultura dibullera, en el que sólo había muros grises y paredes carcomidas por los años que se convirtieron en lienzos multicolores que sobresalen en las paredes del cementerio central de Dibulla, en donde se retratan con pinceladas imágenes propias de la identidad de Dibulla. Esta obra cuenta con 95 metros de largo quedando vista al mar y en la que se pueden encontrar 14 murales que procrean y representan diferentes iconos patrimoniales. Esta galería de murales que rescatan el espacio del cementerio público deteriorado, son ahora puntos de encuentro para lugareños y turistas en la que mediante esta multicultural obra de arte que se descubren en un recorrido al transitar por la calle primera de Dibulla, incorporándole una nueva estética visual al paisaje urbano',
  },
  {
    id: 16,
    name: 'Plaza de 5 y tarima Ender Alvarado Varela',
    image: '/assets/img/atractive/plazade5.jpeg',
    tipoAtractivoId: 3,
    ubicacionId: 11,
    description:
      'Frente al mar Caribe se encuentra ubicada una fastuosa plaza, que converge momentos de encuentro y que proyecta las festividades más importantes de la población de La Punta de los Remedios, “sus carnavales”; imponente, con vista al mar se encuentra la tarima que recibe el nombre “Tarima Ender Alvarado Varela” un reconocido acordeonero que dejó un legado inmortalizado en sus composiciones musicales.La plaza de La Punta de los Remedios y la tarima es un lugar que se puede disfrutar cualquier día de la semana en el que se evidencian elementos de la cultura de esta colorida y alegre población. Playas paradisíacas, con una arena color caramelo y surcadas por palmeras que intentan acercarse al mar. Sus playas son un remanso de paz. La población está ubicada en un sector enmontado a orillas del océano, por lo que la vista del mar es un espectáculo con las olas reventando varios metros por debajo. Lo que caracteriza a La Punta de los Remedios es la calidez de los punteros, gentilicio de sus habitantes, quienes acogen con gran amabilidad a los visitantes.',
  },
  {
    id: 17,
    name: 'Virgen Inmaculada concepción',
    image: '/assets/img/atractive/virgeninmaculadaconcepcion.jpeg',
    tipoAtractivoId: 4,
    ubicacionId: 1,
    description:
      'En el pueblo de Campana se celebra sus Fiestas Patronales. Cada 8 de diciembre, los habitantes del área urbana y rural participan de estas festividades que por décadas han refrendado el fervor de sus fieles. Con una programación habitual, que el proceso de la fe, las procesiones son necesarias cada año y en el desarrollo de los eventos religiosos la iglesia católica desarrolla una eucaristía en homenaje a la Virgen. Este día se acostumbra a encender velas y luces en honor a la Virgen y los campaneros lo convierten en un ritual inexcusable en la que se conjuga lo religioso con lo cultural.',
  },
  {
    id: 18,
    name: 'Virgen nuestra Señora del Pilar',
    image: '/assets/img/atractive/nuestrasenoradepilar.jpg',
    tipoAtractivoId: 4,
    ubicacionId: 3,
    description:
      'Con una amplia programación cultural, deportiva y religiosa se lleva a cabo la celebración de las Fiestas Patronales del pueblo Dibullero, en honor a la virgen Nuestra Señora del Pilar, en dónde asisten sus feligreses entre oriundos y visitantes. Un acontecimiento que calca la cultura e idiosincrasia de un pueblo entregado a la fe y al fervor de su santa patrona. De esta manera, por devoción el pueblo de Dibulla, cada 12 de octubre revive su fe para conmemorar esta importante fecha, en medio de la procesión con la imagen venerada y la celebración de la Santa Misa.',
  },
  {
    id: 19,
    name: 'Teatro Elsy y Teatro Lesvia',
    image: '/assets/img/atractive/teatroelsylesvia.jpg',
    tipoAtractivoId: 4,
    ubicacionId: 15,
    description:
      '"En medio de la calle tercera de la cabecera municipal de Dibulla se encuentran ubicadas las dos infraestructura que en tiempos pasados eran lugares de encuentro cultural dónde un teatro al aire libre promovía la cultura del cine proyectado en horas de la noche, para la población de Dibulla estos dos lugares hicieron parte de momentos especiales de encuentros entre familiares y amigos, en el que se proyectaban películas favoritas y de gran contenido, pero que hoy día se convierten en sitios de interés cultural, que a pesar de ser lugares abandonos de propiedad privada, su arquitectónicas fachadas se proclaman como un lugar de interés para el público. No se pueden hacer visitas en su interior pero aún contamos con estas reliquias arquitectónicas tradicionales que se ha convertido en patrimonio tangible de nuestro municipio por sus significados tradicionales y culturales"',
  },
  {
    id: 20,
    name: 'Casas con nombres',
    image: '/assets/img/atractive/casaconnombres.jpeg',
    tipoAtractivoId: 4,
    ubicacionId: 15,
    description:
      'Es muy común y llamativo al transitar por las calles de nuestro municipio encontrar en las fachadas de las casas un nombre que identifica la vivienda, antiguamente con estos nombre era que se podían establecer con mayor facilidad las ubicaciones o direcciones de las viviendas familiares y que hoy día aun continúan vigentes, las cuales se convierten en patrimonios de nuestro municipio puesto que hacen parte de las costumbres e idiosincrasia y que forman parte de nuestra identidad cultural. Lo más curioso de estas tradicionales casas con nombres es su peculiar colorido y aparte muestran una interesante obra arquitectónica que presentan particularidades excepcionales en su diseño y estética.',
  },
  {
    id: 21,
    name: 'La Chimenea',
    image: '/assets/img/atractive/lachimenea.jpeg',
    tipoAtractivoId: 4,
    ubicacionId: 15,
    description:
      '"“La Chimenea” es un antiguo Ingenio Azucarero, que se convirtió en patrimonio histórico de Dibulla, ubicada a 2.5 Kms Vía a la Troncal del Caribe de la Cabecera Municipal. Este es el último vestigio de lo que alguna vez fue un próspero ingenio azucarero a orillas del río Jerez en el ocaso del siglo XIX.  Este histórico lugar donde se procreo ‘La Hacienda’, en la que sus creadores lograron sacar adelante dicho proyecto con fines industriales, dada la estratégica ubicación geográfica y el intercambio comercial entre la localidad de Dibulla y las islas del Caribe permitió un auge comercial y económico para esta población. En este lugar culmina el sendero que conduce al cerro, y en el que se encuentra una construcción de ladrillo y cemento que corresponde a la infraestructura de un trapiche que procesaba la caña que posteriormente era comercializada. Según la tradición oral, todo el hierro que hacía parte de la construcción se extrajo; quedando la vieja chimenea que se erige en medio de la vegetación como una reliquia que rememora el paso del tiempo."',
  },
  {
    id: 22,
    name: 'Santa Rita de Casia',
    image: '/assets/img/atractive/santaritadecasia.jpg',
    tipoAtractivoId: 4,
    ubicacionId: 5,
    description:
      'La Punta de los Remedios, es también uno de los corregimientos del municipio de Dibulla, donde a un costado de la plaza se encuentra la capilla Santa Rita de Casia, que reúne a los fieles católicos de esta población y en la que todos los 22 de mayo, esta pacifica comunidad celebra sus fiestas patronales con la octava el 29 del mismo mes; en el que se celebra la memoria litúrgica de Santa Rita de Casia, patrona de las causas imposibles, mujer piadosa de gran devoción, quien tras morir su marido y sus dos hijos se convirtió en religiosa del convento agustiniano.',
  },
  {
    id: 23,
    name: 'Virgen del Carmen',
    image: '/assets/img/atractive/virgendelcarmen.jpg',
    tipoAtractivoId: 4,
    ubicacionId: 6,
    description:
      'Los habitantes de Las Flores celebran las fiestas en honor a nuestra señora del Carmen, patrona de los conductores, navegantes, Fuerzas militares y Policía, quienes con diferentes actividades religiosas y culturales conmemoran la celebración de la virgen. En las principales calles de Las flores los 16 de Julio de cada año, se ven invadidas por automóviles que rodean la imagen de la Virgen del Carmen, la cual es colocada en un vehículo y paseada por la troncal del Caribe. El fervor por la Virgen del Carmen es notorio en los pobladores de Las Flores; la fe permanece intacta y para ellos su devoción, obedece a los actos de bondad que su patrona ha tenido con la comunidad; los dos de Diciembre proclaman una rogativa por un acontecimiento que ellos promulgan como milagro; debido a que en época de invierno, una noche de un dos de Diciembre el rio tapias amenazaba con desaparecer a la población; hecho que los motivó a sacar de la iglesia la imagen de la virgen del Carmen y colocarla frente a la cañada del rio, con sus oraciones y manifestaciones de fe, ellos comentan que el agua del rio llegó hasta las patas de la mesa dónde se encontraba la imagen de su patrona, por lo que no hay un día de esta fecha que ellos no agradezcan a la virgen del Carmen su acto de bondad.',
  },
  {
    id: 24,
    name: 'San Martín de Tours',
    image: '/assets/img/atractive/sanmartintours.jpeg',
    tipoAtractivoId: 4,
    ubicacionId: 7,
    description:
      'En el corregimiento de Mingueo se lleva a cabo las fiestas patronales de San Martín de Tour, como tradición, cuando llegaban las fiestas patronales colocaban la imagen de San Martín en la carretera de la troncal del Caribe con una pita atravesada en la vía para pedir colaboración a los vehículos que transitaban por esta carretera, pero en ocasiones algunos transeúntes se saltaban la cuerda tumbando la imagen del santo, esto, ocasionaba que se le hicieran muchos arreglos y fue tanto así que cambiaba el aspecto del caballo sobre el que se postra la imagen del santo',
  },
  {
    id: 25,
    name: 'San isidro Labrador',
    image: '/assets/img/atractive/sanisidrolabrador.jpg',
    tipoAtractivoId: 4,
    ubicacionId: 9,
    description:
      'Las fiestas de San Isidro son unas fiestas patronales en honor a Isidro Labrador, celebradas cada 15 de mayo, en el corregimiento de Palomino en la que se incluyen además de los actos litúrgicos y religiosos, programaciones deportivas y culturales. Como de costumbre todos los años los habitantes de este emporio turístico del municipio de Dibulla, viven una celebración religiosa organizada por la parroquia que lleva su nombre “SAN ISIDRO LABRADOR” en la que sus fieles devotos son sus protagonistas. Los agricultores de la zona, no faltan a esta celebración en la que agradecen a su  patrono por el año productivo o en su defecto hacerle una petición especial; cabe resaltar que San Isidro, es el patrono de los agricultores, siendo esta la principal actividad económica; según el plan agropecuario Municipal, el 70% de la población de Palomino son pequeños productores dedicados a la agricultura, en la que principalmente se cultiva ñame, plátano, maíz, yuca y mango.',
  },
  {
    id: 26,
    name: 'Virgen del Carmen',
    image: '/assets/img/atractive/virgendelcarmen.jpg',
    tipoAtractivoId: 4,
    ubicacionId: 12,
    description:
      'Las fiestas de San Isidro son unas fiestas patronales en honor a Isidro Labrador, celebradas cada 15 de mayo, en el corregimiento de Palomino en la que se incluyen además de los actos litúrgicos y religiosos, programaciones deportivas y culturales. Como de costumbre todos los años los habitantes de este emporio turístico del municipio de Dibulla, viven una celebración religiosa organizada por la parroquia que lleva su nombre “SAN ISIDRO LABRADOR” en la que sus fieles devotos son sus protagonistas. Los agricultores de la zona, no faltan a esta celebración en la que agradecen a su  patrono por el año productivo o en su defecto hacerle una petición especial; cabe resaltar que San Isidro, es el patrono de los agricultores, siendo esta la principal actividad económica; según el plan agropecuario Municipal, el 70% de la población de Palomino son pequeños productores dedicados a la agricultura, en la que principalmente se cultiva ñame, plátano, maíz, yuca y mango.',
  },
  {
    id: 27,
    name: 'Río Jerez',
    image: '/assets/img/atractive/riojerez.jpg',
    tipoAtractivoId: 5,
    ubicacionId: 8,
    description:
      'Es un río de aguas cristalinas y suelo rocoso que nace en la Sierra Nevada de Santa Marta y desemboca en el mar Caribe recorriendo una distancia aproximada de 423 kilómetros.A lo largo de su recorrido por el municipio se ubican diferentes puntos de acceso aptos para el baño con vegetación en sus alrededores. Entre ellos el Balneario Montecristo un paraje natural con acceso privado donde se puede disfrutar de las aguas cristalinas y tranquilas del río rodeado de árboles. Otro punto muy visitado por los pobladores locales es en el Puente del río Jerez por debajo del puente se accede al río que entre las rocas forma pozos de agua aptos para el baño.',
  },
  {
    id: 28,
    name: 'Río Ancho',
    image: '/assets/img/atractive/rioancho.jpeg',
    tipoAtractivoId: 5,
    ubicacionId: 8,
    description:
      'El río nace en la Sierra Nevada de Santa Marta hace un recorrido aproximado de 244 kilómetros hasta su desembocadura en el Mar Caribe se caracteriza por sus aguas cristalinas y suelo rocoso. Ofrece diferentes puntos para el disfrute de sus pobladores y visitantes entre ellos El Saltillo ubicado al pie de la Sierra pasando el pueblo kogui de Tungueka El Pozo el Pozo Romántico y el Pozo Azul.',
  },
  {
    id: 29,
    name: 'Río Cañas',
    image: '/assets/img/atractive/riocanas.jpeg',
    tipoAtractivoId: 5,
    ubicacionId: 8,
    description:
      'Este río es uno de los preferidos por la población local debido a su cercanía al pueblo de Mingueo por sus refrescantes aguas cristalinas y su playa de arena. Nace en la Sierra Nevada de Santa Marta y finaliza su recorrido en el mar Caribe su cuenca tiene un área de 13.801 hectáreas.El acceso más visitado al río se da en inmediaciones del puente que lo atraviesa ubicado en el corregimiento de Mingueo. Esta zona sirve de balneario natural debido a que el río forma un codo antes de llegar al puente permitiendo la acumulación de agua y formación de pozas aptas para el baño. Además en el recorrido del río se tiene numerosas rocas de diferentes tamaños.',
  },
  {
    id: 30,
    name: 'Boca de la Enea',
    image: '/assets/img/atractive/bocaenea.jpg',
    tipoAtractivoId: 5,
    ubicacionId: 8,
    description:
      'Se conoce como Boca de La Enea a la desembocadura del río Tapia en el Mar Caribe. Es un sitio de belleza paisajística al tener manglares cocoteras y playa. Entre la fauna que se puede observar está el caimán aguja cascabeles monos venados y aves acuáticas.Es el límite natural entre los municipios de Riohacha y Dibulla en el área se tienen madreviejas y ciénagas colindando con la Laguna Grande del Santuario de Fauna y Flora de Flamencos.',
  },
  {
    id: 31,
    name: 'Cascada Los Cocos',
    image: '/assets/img/atractive/cascadaloscocos.jpg',
    tipoAtractivoId: 6,
    ubicacionId: 2,
    description:
      'Después de una caminata a través del bosque, podrás conocer el hermoso paisaje de fauna y flora de los senderos de este hermoso lugar, terminaras disfrutando de un baño tranquilo en la cascada natural de agua cristalina, de aproximadamente 4 metros de altura y 3 de profundidad.',
  },
  {
    id: 32,
    name: 'Playas y desdembocadura Rio Jerez',
    image: '/assets/img/atractive/riojerez.jpg',
    tipoAtractivoId: 6,
    ubicacionId: 3,
    description:
      'Es un río de aguas cristalinas y suelo rocoso que nace en la Sierra Nevada de Santa Marta y desemboca en el mar Caribe recorriendo una distancia aproximada de 423 kilómetros.A lo largo de su recorrido por el municipio se ubican diferentes puntos de acceso aptos para el baño con vegetación en sus alrededores. Entre ellos el Balneario Montecristo un paraje natural con acceso privado donde se puede disfrutar de las aguas cristalinas y tranquilas del río rodeado de árboles. Otro punto muy visitado por los pobladores locales es en el Puente del río Jerez por debajo del puente se accede al río que entre las rocas forma pozos de agua aptos para el baño.',
  },
  {
    id: 33,
    name: 'Cascada Larga La Vida',
    image: '/assets/img/atractive/cascadalargavida.jpg',
    tipoAtractivoId: 6,
    ubicacionId: 3,
    description:
      'Paraje natural con cascadas de ensueños donde se experimenta la magia de la sierra nevada y la frescura del agua fría, convirtiéndose en un lugar atractivo para visitar.',
  },
  {
    id: 34,
    name: 'Cascada Mamey',
    image: '/assets/img/atractive/cascadamamey.jpg',
    tipoAtractivoId: 6,
    ubicacionId: 4,
    description:
      'Hermoso paisajes y una bella fuente de agua se encuentran en el sector llamado el Mamey, ubicado en el Municipio de Dibulla, un lugar paradisiaco apto para abandonar los afanes del día a día. Se dice, que estas aguas son milagrosas, curan enfermedades y liberan depresiones',
  },
  {
    id: 35,
    name: 'Poza Romantica',
    image: '/assets/img/atractive/pozaromantica.jpg',
    tipoAtractivoId: 6,
    ubicacionId: 7,
    description:
      'Él espacio ideal para descansar, para compartir momentos inolvidables, podrás respirar aire puro, que da tranquilidad, que te hace perder en el sonido de las aves, existen piedras gigantes para reposar mientras el agua corre, a la sombra de un árbol de mango, te conectas directo con la naturaleza y te pierdes del mundo.',
  },
  {
    id: 36,
    name: 'Pozo Caiman',
    image: '/assets/img/atractive/pozocaiman.jpg',
    tipoAtractivoId: 6,
    ubicacionId: 7,
    description:
      'Comunidad indígena que refleja nuestra cultura, podrás conocer las creencia y costumbres de los indígenas kogui, estas experiencias se compartirán con las máximas autoridades del pueblo. Actividad que nos lleva a sentirnos guajiros de corazón.',
  },
  {
    id: 37,
    name: 'Cascada Alto San Jorge',
    image: '/assets/img/atractive/cascadasanjorge.jpg',
    tipoAtractivoId: 6,
    ubicacionId: 7,
    description:
      'Roca de 12mts de alto, donde nacen varios hilos de aguas cristalinas creándose así la Cascada Alto San Jorge, formando un pozo natural con propiedades curativas y rejuvenecedoras.',
  },
  {
    id: 38,
    name: 'La Poza',
    image: '/assets/img/atractive/lapoza.jpg',
    tipoAtractivoId: 6,
    ubicacionId: 7,
    description:
      'Corriente de agua cristalina que se represa al golpear con el cerro de piedra, uno de los lugares más profundo del rio cañas. Tiene la particularidad que entre parte de sus rocas y tierra, se forman arcillas naturales que los turistas colocan sobre su piel. Frescura y exfoliante en un tesoro de agua fría. Es el lugar más visitado por nativos, por ser el sitio de descanso para compartir en familia y amigos',
  },
  {
    id: 39,
    name: 'Desembocadura del rio de Palomino',
    image: '/assets/img/atractive/desembocadurariopalomino.jpg',
    tipoAtractivoId: 6,
    ubicacionId: 9,
    description:
      'Atractivo y mágico lugar, donde te puedes dejar deslumbrar por la magia de la naturaleza y su gastronomía, perfecto para relajarse, tomar el sol, nadar y pasear por los manglares de la madreselva, segmentos de agua dulce que desembocan al mar. Allí puedes observar a los locales pescando y avistar distintas aves. Sus atardeceres son mágicos, con una puesta de sol sobre las montañas de la Sierra Nevada de Santa Marta.',
  },
  {
    id: 40,
    name: 'Tubing',
    image: '/assets/img/ubicaciones/tubing.jpg',
    tipoAtractivoId: 6,
    ubicacionId: 9,
    description:
      'Se conoce como una de las principales actividades turísticas de este hermoso lugar, es hacer el descenso por las cálidas y cristalinas aguas del río, realizar caminatas por senderos ecológicos y conocer nuestra cultura indígena, sin duda alguna es un recorrido que le permite al viajero conectarse con la naturaleza y sus ecosistemas. Considerada como Patrimonio natural y cultural del municipio donde tendrás la oportunidad de conectarte contigo mismo y sentir el silencio de la naturaleza.',
  },
  {
    id: 41,
    name: 'Surf',
    image: '/assets/img/atractive/surf.jpg',
    tipoAtractivoId: 6,
    ubicacionId: 9,
    description:
      'Disfrutar de la adrenalina de este deporte extreme y demás actividades acuáticas se viven con pasión en las playas paradisiacas del Municipio de Dibulla.',
  },
  {
    id: 42,
    name: 'La Caimana',
    image: '/assets/img/atractive/lacaimana.jpg',
    tipoAtractivoId: 6,
    ubicacionId: 10,
    description:
      'Lugar ideal para pasear, sus aguas transparentes permiten que se pueda ver el reflejo de nuestra esencia, nuestro espíritu, y se disfrute libremente de un día diferente, lleno de paz y tranquilidad.',
  },
  {
    id: 43,
    name: 'Pueblos indigenas Tungeka',
    image: '/assets/img/atractive/indigenatungeka.jpeg',
    tipoAtractivoId: 6,
    ubicacionId: 12,
    description:
      'Comunidad indígena que refleja nuestra cultura, podrás conocer las creencia y costumbres de los indígenas kogui, estas experiencias se compartirán con las máximas autoridades del pueblo. Actividad que nos lleva a sentirnos guajiros de corazón.',
  },
  {
    id: 44,
    name: 'Balneario las Gaviotas',
    image: '/assets/img/atractive/lasgaviotas.jpg',
    tipoAtractivoId: 6,
    ubicacionId: 12,
    description:
      'Atractivo natural donde podrás disfrutar de un lugar virgen con avistamiento de aves endémicas completando el paraje místico entre rio y mar.',
  },
  {
    id: 45,
    name: 'Represa 13',
    image: '/assets/img/atractive/represa.jpeg',
    tipoAtractivoId: 6,
    ubicacionId: 13,
    description:
      'Corriente de agua cristalina que se represa al golpear con el cerro de piedra, uno de los lugares más profundo del rio cañas. Tiene la particularidad que entre parte de sus rocas y tierra, se forman arcillas naturales que los turistas colocan sobre su piel. Frescura y exfoliante en un tesoro de agua fría. Es el lugar más visitado por nativos, por ser el sitio de descanso para compartir en familia y amigos',
  },
  {
    id: 46,
    name: 'Cascadas Cristalina',
    image: '/assets/img/atractive/cascadacristalina.jpg',
    tipoAtractivoId: 6,
    ubicacionId: 14,
    description:
      'Disfrutarás de una caminata ecológica de 4 horas, conociendo fauna y flora de la región, hasta llegar a la espectacular cascada de agua cristalina, donde nace el rio san salvador, lugar mágico que te permitirá vivir experiencias únicas, volverás una y otra vez, buscando esa paz que solo este paraíso te puede brindar.',
  },
];
