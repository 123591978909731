import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Ubicaciones } from '../../core/data/ubicaciones';
import { IUbicaciones } from '../../core/interfaces/ubicaciones.interface';
import { ITiposAtractivos } from '../../core/interfaces/atractivos.interfaces';
import { TiposAtractivos } from '../../core/data/atractivos';
import { useSelector } from 'react-redux';

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function HeaderComponent() {
  const isHome = useSelector((state: any) => state.isHome);

  const [ubicaciones] = useState(Ubicaciones);
  const miBotonRef = useRef<HTMLButtonElement>(null);

  const listUbicaciones = (): JSX.Element => {
    const items: JSX.Element[] = ubicaciones.map((element: IUbicaciones) => {
      return (
        <li key={element.id}>
            <Link onClick={handleClick} to={'details-ubication/' + element.id}>{element.name} </Link>
        </li>
      );
    });

    return <ul>{items}</ul>;
  };

  const listAtractivos = (): JSX.Element => {
    const rows = TiposAtractivos.filter((sel: ITiposAtractivos) => sel.id < 5);
    const items: JSX.Element[] = rows.map((element: ITiposAtractivos) => {
      return (
        <div className='col-sm-6 col-md-3' key={element.id}>
          {' '}
          <div className='col'>
            <div className='img-wrap'>
              <Link onClick={handleClick} to={'details-atractive/' + element.id}>
                <img
                  src={element.image}
                  height='228'
                  width='350'
                  alt={element.name}
                />
              </Link>
            </div>
            <div className='description'>
              <strong className='title'>
                <Link to={'details-atractive/' + element.id}>
                  {element.name}
                </Link>
              </strong>
              <p>{element.description}</p>
            </div>
          </div>
        </div>
      );
    });

    return <div className='row'>{items}</div>;
  };

  const handleClick = () => {
    // Accede al elemento DOM utilizando la referencia y simula un clic
    if (miBotonRef.current) {
      (miBotonRef.current as HTMLButtonElement).click();
    }
  };

  return (
    <>
      <header
        id='header'
        className={!isHome ? 'black-background' : ''}
      >
        <div className='container-fluid'>
          {/* <!-- logo --> */}
          <div className='logo'>
            <a>
              <img
                className='normal'
                style={{ marginTop: '-22px' }}
                src='/assets/img/logos/logo.png'
                alt='Entrada'
              />
            </a>
          </div>
          {/* <!-- main navigation --> */}
          <nav className='navbar navbar-default'>
            <div className='navbar-header'>
              <button
                type='button'
                className='navbar-toggle nav-opener'
                data-toggle='collapse'
                data-target='#nav'
                ref={miBotonRef}
              >
                <span className='sr-only'>Toggle navigation</span>
                <span className='icon-bar'></span>
                <span className='icon-bar'></span>
                <span className='icon-bar'></span>
              </button>
            </div>
            {/* <!-- main menu items and drop for mobile --> */}
            <div className='collapse navbar-collapse' id='nav'>
              {/* <!-- main navbar --> */}
              <ul className='nav navbar-nav'>
                <li className='dropdown'>
                  <Link
                    className='dropdown-toggle'
                    data-toggle='dropdown'
                    onClick={handleClick}
                    to={'/'}
                  >
                    {' '}
                    Inicio <b className='icon-angle-down'></b>
                  </Link>
                </li>
                <li className='dropdown'>
                  <a className='dropdown-toggle' data-toggle='dropdown'>
                    Ubicaciones <b className='icon-angle-down'></b>
                  </a>
                  <div className='dropdown-menu'>{listUbicaciones()}</div>
                </li>
                <li className='dropdown has-mega-dropdown'>
                  <a className='dropdown-toggle' data-toggle='dropdown'>
                    Atractivos <b className='icon-angle-down'></b>
                  </a>
                  <div className='dropdown-menu'>
                    <div className='drop-wrap'>
                      <div className='drop-holder'>{listAtractivos()}</div>
                    </div>
                  </div>
                </li>
                <li className='dropdown has-mega-dropdown'>
                  <Link to={'recomendaciones/codigo_conducta'}>
                    Recomendaciones
                  </Link>
                </li>
                <li className='dropdown has-mega-dropdown'>
                  <a className='dropdown-toggle' data-toggle='dropdown' onClick={handleClick}>
                    Galeria <b className='icon-angle-down'></b>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}
