// isHomeReducer.js
const initialState = {
  isHome: true, // Estado inicial, estamos en la página de inicio
};

const isHomeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_HOME':
      return { ...state, isHome: action.payload };
    default:
      return state;
  }
};

export default isHomeReducer;
