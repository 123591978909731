import { IUbicaciones } from '../interfaces/ubicaciones.interface';

export const Ubicaciones: IUbicaciones[] = [
  {
    id: 1,
    name: 'Campana',
    image: '/assets/img/ubicaciones/casaaluminio.jpg',
    imageDetail: '/assets/img/ubicaciones/casaaluminio.jpg',
    description: '',
    lat: 11.272415,
    lng: -73.30905,
  },
  {
    id: 2,
    name: 'Casa Aluminio',
    image: '/assets/img/ubicaciones/casaaluminio.jpg',
    imageDetail: '/assets/img/ubicaciones/casaaluminio.jpg',
    description: '',
    lat: 11.272415,
    lng: -73.30905,
  },
  {
    id: 3,
    name: 'Dibulla cabecera municipal',
    image: '/assets/img/ubicaciones/playasdibulla.jpeg',
    imageDetail: '/assets/img/ubicaciones/playasdibulla.jpeg',
    description: '',
    lat: 11.272415,
    lng: -73.30905,
  },
  {
    id: 4,
    name: 'El Mamey',
    image: '/assets/img/ubicaciones/elmamey.jpg',
    imageDetail: '/assets/img/ubicaciones/elmamey.jpg',
    description: '',
    lat: 11.272415,
    lng: -73.30905,
  },
  {
    id: 5,
    name: 'La Punta de los Remedios',
    image: '/assets/img/ubicaciones/puntaremedios.jpeg',
    imageDetail: '/assets/img/ubicaciones/puntaremedios.jpeg',
    description: '',
    lat: 11.272415,
    lng: -73.30905,
  },
  {
    id: 6,
    name: 'Las Flores',
    image: '/assets/img/ubicaciones/lasflorez.jpg',
    imageDetail: '/assets/img/ubicaciones/lasflorez.jpg',
    description: '',
    lat: 11.27109,
    lng: -73.17964,
  },
  {
    id: 7,
    name: 'Mingueo',
    image: '/assets/img/ubicaciones/mingueo.jpeg',
    imageDetail: '/assets/img/ubicaciones/mingueo.jpeg',
    description: '',
    lat: 11.272415,
    lng: -73.30905,
  },
  {
    id: 8,
    name: 'Municipio de Dibulla',
    image: '/assets/img/ubicaciones/dibulla_.jpeg',
    imageDetail: '/assets/img/ubicaciones/dibulla_.jpeg',
    description: '',
    lat: 11.272415,
    lng: -73.30905,
  },
  {
    id: 9,
    name: 'Palomino',
    image: '/assets/img/ubicaciones/palomino.jpg',
    imageDetail: '/assets/img/ubicaciones/palomino.jpg',
    description: '',
    lat: 11.272415,
    lng: -73.30905,
  },
  {
    id: 10,
    name: 'Penjamo, Las Flores',
    image: '/assets/img/ubicaciones/lasflorez.jpg',
    imageDetail: '/assets/img/ubicaciones/lasflorez.jpg',
    description: '',
    lat: 11.272415,
    lng: -73.30905,
  },
  {
    id: 11,
    name: 'Punta de los Remedios',
    image: '/assets/img/ubicaciones/puntaremedios.jpeg',
    imageDetail: '/assets/img/ubicaciones/puntaremedios.jpeg',
    description: '',
    lat: 11.272415,
    lng: -73.30905,
  },
  {
    id: 12,
    name: 'Rio Ancho',
    image: '/assets/img/ubicaciones/rioancho.jpg',
    imageDetail: '/assets/img/ubicaciones/rioancho.jpg',
    description: '',
    lat: 11.2608333,
    lng: -73.4783333,
  },
  {
    id: 13,
    name: 'Rio Claro',
    image: '/assets/img/ubicaciones/rioclaro.jpg',
    imageDetail: '/assets/img/ubicaciones/rioclaro.jpg',
    description: '',
    lat: 11.272415,
    lng: -73.30905,
  },
  {
    id: 14,
    name: 'San Salvador',
    image: '/assets/img/ubicaciones/sansalvador.jpg',
    imageDetail: '/assets/img/ubicaciones/sansalvador.jpg',
    description: '',
    lat: 11.272415,
    lng: -73.30905,
  },
];
