import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Ubicaciones } from '../../core/data/ubicaciones';
import { IUbicaciones } from '../../core/interfaces/ubicaciones.interface';
import { Atractivos } from '../../core/data/atractivos';
import { IAtractivos } from '../../core/interfaces/atractivos.interfaces';
import { useDispatch } from 'react-redux';
import SimpleMap from '../../core/utils/google-maps/maps';
import './details-ubication.css';

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function DetailsUbication() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [ubication, setUbication] = useState<IUbicaciones>(
    Ubicaciones.filter((sel: IUbicaciones) => sel.id === Number(id))[0]
  );

  const tiposAtractivos = (): JSX.Element => {
    const rows = Atractivos.filter(
      (sel: IAtractivos) => sel.ubicacionId === ubication.id
    );
    const items: JSX.Element[] = rows.map((element: IAtractivos) => {
      return (
        <div className="col-md-5 content-img text-center text-uppercase">
          <Link to={'/atractive/' + element.id}>
            <h3 className="row title-img">{element.name}</h3>
          </Link>
          <div className="row col-md-12 img-div">
            <Link to={'/atractive/' + element.id}>
              <img draggable="false" src={element.image} alt="imagen descripcion" />
            </Link>
          </div>
        </div>
      );
    });

    return (
      <div
        className="col-md-6"
        style={{ paddingBottom: '30px', textAlign: 'center', marginTop: '30px' }}
      >
        <h2 className="text-center text-uppercase">{ubication?.name}</h2>
        <hr></hr>
        <h3 className="text-center text-uppercase">Atractivos relacionados</h3>
        <hr></hr>
        {items}
      </div>
    );
  };

  useEffect(() => {
    dispatch({ type: 'SET_HOME', payload: false });
    setUbication(
      Ubicaciones.filter((sel: IUbicaciones) => sel.id === Number(id))[0]
    );

    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Opcional: agrega un desplazamiento suave
    });
  }, [dispatch, id]);

  return (
    <>
      {/* <Slider></Slider> */}
      <main id="main">
        {/* <!-- main tour information --> */}
        <div className="col-md-6">
          <div className="col-md-12">
            <img
              className="img-ubication col-md-12"
              draggable="false"
              src={ubication.imageDetail}
              alt="imagen descripcion"
            />
          </div>
          <div
            className="col-md-12 map-web"
            style={{ marginTop: '40px', marginBottom: '20px' }}
          >
            <h3 className="text-center text-uppercase">
              <b>Encuentranos facil</b>
            </h3>
            <SimpleMap
              latitud={11.29425564746964}
              longitud={-73.26262888366342}
            />
          </div>
        </div>
        {tiposAtractivos()}
        <div
          className="col-md-12 map-movil"
          style={{ marginTop: '40px', marginBottom: '20px' }}
        >
          <h3 className="text-center text-uppercase">
            <b>Encuentranos facil</b>
          </h3>
          <SimpleMap
            latitud={ubication.lat}
            longitud={ubication.lng}
          />
        </div>
      </main>
    </>
  );
}
