/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import Slider from '../../components/slider/slider';
import { Ubicaciones } from '../../core/data/ubicaciones';
import { IUbicaciones } from '../../core/interfaces/ubicaciones.interface';
import { Link } from 'react-router-dom';
import { TiposAtractivos } from '../../core/data/atractivos';
import { ITiposAtractivos } from '../../core/interfaces/atractivos.interfaces';
import { useDispatch } from 'react-redux';
import './home.css';

export default function HomeComponent() {
  const dispatch = useDispatch();
  const [ubicaciones] = useState(Ubicaciones);

  const listUbicaciones = (): JSX.Element => {
    const rows = ubicaciones.filter((sel: IUbicaciones) => sel.id < 10);
    const items: JSX.Element[] = rows.map((element: IUbicaciones) => {
      return (
        <li key={element.id} style={{ height: '160px' }}>
            <Link className="thumbnail" style={{ height: '100%' }} to={'details-ubication/' + element.id}>
              <img
                src={element.image}
                style={{ height: '100%', width: '100%' }}
                alt={element.name}
              />
              <span className="hover icon-bird"></span>
              <span
                className="info"
                style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}
              >
                {element.name}
              </span>
            </Link>
        </li>
      );
    });

    return <ul className='gallery-list gallery-with-icon'>{items}</ul>;
  };

  const listAtractivos = (): JSX.Element => {
    const rows = TiposAtractivos.filter((sel: ITiposAtractivos) => sel.id < 10);
    const items: JSX.Element[] = rows.map((element: ITiposAtractivos) => {
      return (
        <article
          key={element.id}
          className='col-sm-6 col-md-4 article has-hover-s3'
        >
          <div className='img-wrap'>
            <Link to={'details-atractive/' + element.id} style={{height: '100%'}}>
              <img
                src={element.image}
                style={{height: '300px'}}
                alt={element.name}
              />
            </Link>
          </div>
          <h3>
            <Link to={'details-atractive/' + element.id}>{element.name}</Link>
          </h3>
          <p>
            This is Photoshop's version of Lorem Ipsum. Proin gravida nibh vel
            velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum
            auctor, nisi elit consequat ipsum,
          </p>
        </article>
      );
    });

    return <div className='row db-3-col'>{items}</div>;
  };

  setTimeout(() => {
    dispatch({ type: 'SET_HOME', payload: true });
  }, 1000);
  return (
    <>
      <Slider></Slider>
      <main id="main">
        <section className="content-block bg-white">
          <div className="container">
            <header className="content-heading">
              <h2 className="main-heading">VIVE LA AVENTURA</h2>
              <span className="main-subtitle">
                Se parte de las personas en conocer estos maravillosos lugares.
              </span>
              <div className="seperator"></div>
            </header>
            <div className="adventure-holder gallery-home-holder">
              <div className="row">
                <div className="col-md-6 img-block">
                  {/* <!-- gallery list --> */}
                  {listUbicaciones()}
                </div>
                <div className="col-md-6 text-block">
                  <div className="centered">
                    <h2 className="intro-heading">Ubicaciones</h2>
                    <p className="intro">
                      Dibulla ofrece la oportunidad de conectarse con la
                      naturaleza, disfrutar de la herencia cultural de la región
                      y crear recuerdos inolvidables en un entorno único en la
                      costa caribeña de Colombia, cuenta con numerosas
                      ubicaciones que atraen a visitantes de todas partes.
                    </p>
                    <Link
                      className="btn btn-info-sub btn-md btn-shadow radius"
                      to={'all-ubications'}
                    >
                      Ver todas
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- special block --> */}
        <aside className="special-block">
          <div className="container">
            <p className="special-text">
              Para saber mas sobre Dibulla contactanos.<br></br>
              <strong>
                Email:
                <a href="mailto:turismocultura@dibulla-laguajira.gov.co">
                  turismocultura@dibulla-laguajira.gov.co
                </a>
              </strong>
            </p>
          </div>
        </aside>
        {/* <!-- article list holder --> */}
        <div className="content-block content-spacing">
          <div className="container">
            <header className="content-heading">
              <h2 className="main-heading">ATRACTIVOS POPULARES</h2>
              <span className="main-subtitle">
                Conoce y disfruta de estos emocionantes lugares y festivales
                culturales!
              </span>
              <div className="seperator"></div>
            </header>
            <div className="content-holder at-p">{listAtractivos()}</div>
          </div>
        </div>
        {/* <!-- couter block --> */}
        <aside className="count-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-6 col-md-3 block-1">
                <div className="holder">
                  <span className="icon icon-step"></span>
                  <span className="info">
                    <span className="counter">14</span>
                  </span>
                  <span
                    className="txt"
                    style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}
                  >
                    UBICACIONES
                  </span>
                </div>
              </div>
              <div className="col-xs-6 col-md-3 block-2">
                <div className="holder">
                  <span className="icon icon-fish-jumping"></span>
                  <span className="info">
                    <span className="counter">6</span>
                  </span>
                  <span
                    className="txt"
                    style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}
                  >
                    ATRACTIVOS
                  </span>
                </div>
              </div>
              <div className="col-xs-6 col-md-3 block-3">
                <div className="holder">
                  <span className="icon icon-tree"></span>
                  <span className="info">
                    <span className="counter">46</span>
                  </span>
                  <span
                    className="txt"
                    style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}
                  >
                    LUGARES Y EVENTOS
                  </span>
                </div>
              </div>
              <div className="col-xs-6 col-md-3 block-4">
                <div className="holder">
                  <span className="icon icon-duration"></span>
                  <span className="info">
                    <span className="counter">8973</span>
                  </span>
                  <span
                    className="txt"
                    style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}
                  >
                    VISITAS
                  </span>
                </div>
              </div>
            </div>
          </div>
        </aside>
        {/* <!-- featured adventure content --> */}
        <div className="featured-content adventure-holder">
          <div className="container-fluid">
            <div className="row same-height">
              <div className="col-md-6 image height">
                <div className="bg-stretch">
                  <img
                    src="/assets/img/historias/gastronomia.jpg"
                    height="627"
                    width="960"
                    alt="imagen descripcion"
                  />
                </div>
              </div>
              <div className="col-md-6 text-block height">
                <div className="centered">
                  <h2 className="intro-heading">Rutas gastronómicas</h2>
                  <p className="intro">
                    Este es el plan para los amantes de los nuevos sabores,
                    especialmente si de comida costeña se trata. La ruta la
                    podrás hacer fácilmente acompañado de un guía local, quien
                    te llevará por los locales de las cocineras más reconocidas
                    del sector. ¿Te suenan los buñuelos de yuca, la arepa de
                    palo, el dulce de coco o la tradicional arepa de huevo?
                    Todas estas son preparaciones típicas de la comida costeña,
                    elaboradas por cocineras locales a las que podrás conocer y
                    escuchar sobre sus relatos de las costumbres culinarias del
                    sector.
                  </p>
                </div>
              </div>
            </div>
            <div className="row same-height">
              <div className="col-md-6 image height">
                <div className="bg-stretch">
                  <img
                    src="/assets/img/historias/maryriodibulla.jpg"
                    height="627"
                    width="960"
                    alt="imagen descripcion"
                  />
                </div>
              </div>
              <div className="col-md-6 text-block height">
                <div className="centered">
                  <h2 className="intro-heading">
                    ¿Qué hacer en Dibulla, Guajira?
                  </h2>
                  <p className="intro">
                    En Dibulla existen varios corregimientos, los cuales son
                    Mingueo, La Punta de los Remedios, Las Flores, Rioancho y
                    Palomino. Este último es uno de los destinos más visitados,
                    gracias a sus hermosas playas y a la amplia oferta de
                    restaurantes allí existente. Por lo demás, en todos los
                    otros corregimientos no encontrarás grandes multitudes y
                    podrás disfrutar de la serenidad del mar a solas o con tu
                    acompañante de viaje.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- browse block --> */}
        <div className="browse-block">
          <div className="browse-destination column">
            <a>
              <span>MUCHOS DESTINOS</span>
            </a>
          </div>
          <div className="browse-adventures column">
            <a>
              <span>MUCHOS SABORES</span>
            </a>
          </div>
        </div>
        {/* <!-- content block with guide info --> */}
        <section className="content-block guide-sub guide-add bg-white">
          <div className="container">
            <header className="content-heading">
              <h2 className="main-heading">Ellos Disfrutaron</h2>
              <span className="main-subtitle">
                Muchos lugares para que disfrutes y seas libre
              </span>
              <div className="seperator"></div>
            </header>
            <div className="content-holder">
              <div className="row">
                <div className="col-sm-6 col-md-4 img-article">
                  <div className="holder">
                    <div className="img-wrap">
                      <img
                        src="/assets/img/listing/img-13.jpg"
                        height="436"
                        width="370"
                        alt="imagen descripcion"
                      />
                    </div>
                    <div className="caption">
                      <h3 className="small-space">Fernando Ibañez</h3>
                      <span className="designation">Velocista</span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 img-article">
                  <div className="holder">
                    <div className="img-wrap">
                      <img
                        src="/assets/img/listing/img-14.jpg"
                        height="436"
                        width="370"
                        alt="imagen descripcion"
                      />
                    </div>
                    <div className="caption">
                      <h3 className="small-space">Isabel Perez</h3>
                      <span className="designation">Creadora de contenido</span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 img-article">
                  <div className="holder">
                    <div className="img-wrap">
                      <img
                        src="/assets/img/listing/img-15.jpg"
                        height="436"
                        width="370"
                        alt="imagen descripcion"
                      />
                    </div>
                    <div className="caption">
                      <h3 className="small-space">Martin Arias</h3>
                      <span className="designation">Ciclista</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- testimonial block --> */}
        <div
          className="testimonial-holder parallax"
          data-stellar-background-ratio="0.25"
          id="testimonial-home-page"
        >
          <div className="container">
            <div id="testimonial-home-slide">
              <div className="slide">
                <blockquote className="testimonial-quote">
                  <div className="img">
                    <img
                      src="/assets/img/thumbs/img-01.jpg"
                      height="112"
                      width="112"
                      alt="imagen descripcion"
                    />
                  </div>
                  <div className="text">
                    <cite>JOHNNY JR. ARIAS</cite>
                    <q>
                      "¡Las playas son asombrosas! Me encanta jugar en la arena,
                      hacer castillos y buscar conchas marinas. También me
                      encanta el sonido de las olas y cómo puedo correr y
                      chapotear en el agua. ¡Las playas son mi lugar favorito en
                      el mundo entero!"
                    </q>
                  </div>
                </blockquote>
              </div>
              <div className="slide">
                <blockquote className="testimonial-quote">
                  <div className="img">
                    <img
                      src="/assets/img/thumbs/img-03.jpg"
                      height="112"
                      width="112"
                      alt="imagen descripcion"
                    />
                  </div>
                  <div className="text">
                    <cite>GLORIA JIMENEZ</cite>
                    <q>
                      "Este lugar es simplemente hermoso. Las playas son tan
                      pintorescas y fotogénicas. Disfruté cada momento aquí,
                      caminando por la orilla, viendo la puesta de sol y
                      capturando cada vista increíble con mi cámara. Cada rincón
                      es una obra maestra, y estoy enamorada de la paz que
                      encontré aquí."
                    </q>
                  </div>
                </blockquote>
              </div>
              <div className="slide">
                <blockquote className="testimonial-quote">
                  <div className="img">
                    <img
                      src="/assets/img/thumbs/img-02.jpg"
                      height="112"
                      width="112"
                      alt="imagen descripcion"
                    />
                  </div>
                  <div className="text">
                    <cite>ALFONSO ZAPATA</cite>
                    <q>
                      "Fue una experiencia increíble. Por fin pude descansar y
                      relajarme, algo que realmente necesitaba. Las playas aquí
                      son tranquilas y serenas. Pasé mis días tumbado en la
                      arena, leyendo un buen libro y disfrutando del sonido de
                      las olas. Además, conocí gente amable y pude explorar este
                      lugar maravilloso. Fue exactamente lo que necesitaba para
                      recargar mis energías."
                    </q>
                  </div>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
        <div className="subfooter">
          <div className="image-container">
              <img  src={"/assets/img/icono_alcaldia_01.jpg"} alt="Imagen 1" id="image1"/>
          </div>
          <div className="image-container">
              <img src={"/assets/img/icono_alcaldia_02.jpg"} alt="Imagen 2" id="image2"/>
          </div>
          <h3 className='title-subfooter'>Convocatoria 810 de 2018 - Formación de capital humano de alto nivel para las regiones-Guajira</h3>
          <br></br>
        </div>
      </main>
    </>
  );
}
