/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';
import './view-pdf.css';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
export default function ViewPdf() {
  const dispatch = useDispatch();
  const { pdf } = useParams();
  const [pdfUrl, setPdfUrl] = useState('');

  useEffect(() => {
    dispatch({ type: 'SET_HOME', payload: false });
    setPdfUrl(`${window.location.origin}/files/${pdf}.pdf`);

    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Opcional: agrega un desplazamiento suave
    });
  }, [dispatch, pdf]);
  return (
    <>
      <div className='container-iframe'>
        <div className='iframe-container'>
          <iframe src={pdfUrl} width={'100%'} height={'100%'}></iframe>
        </div>
      </div>
    </>
  );
}
